@use '../' as *;

.breadcrumbs {
    text-align: center;
    h3 {
        text-transform: uppercase;
    }
}
.tf-title {
    margin-bottom: 15px;
    text-align: center;
    .sub-title {
        line-height: 1.6;
        color: $bg-2;
        text-transform: uppercase;
        font-family: $font-main2;
        margin-bottom: 8px;
        letter-spacing: 0.6px;
    }
    .title {
        text-transform: uppercase;
    }
    &.st2 {
        text-align: left;
    }
}
.post {
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    .image {
        position: relative;
        margin-bottom: 32px;
        .tag {
            @include flex(center,center);
            position: absolute;
            top: 20px;
            left: 30%;
            font-family: $font-main;
            font-size: 14px;
            line-height: 22px;
            text-transform: uppercase;
            background-color: $bg-2;
            font-weight: $font-w7;
            height: 32px;
            padding: 5px 12px;
            color: $white;
        }
    }
    .title {
        text-transform: capitalize;
        letter-spacing: 0;
        margin-bottom: 9px;
    }
    .meta-post {
        @include flex2;
        margin-bottom:  8px;
        li {
            margin-right: 10px;
            padding-right: 20px;
            position: relative;
            &::after {
                content: "|";
                position: absolute;
                top: 0;
                right: 0;
            }
        &:last-child::after{
            display: none;
        }
        span {
            margin-right: 11px;
        }
        }
        li
        a {
            color: $white;
            &:hover {
                color: $color-hover;
            }
        }
        
    }
    p {
        letter-spacing: 0;
    }
    .read-more {
        @include font-16;
        text-transform: uppercase;
        font-weight: $font-w7;
        font-family: $font-main;
        color: $white;
        letter-spacing: 0.6px;
        &:hover {
            color: $color-hover;
        }
    }
    &.inner-post {
        margin-top: 40px;
        @include flex(center,flex-start);
        .image {
            position: relative;
            margin-bottom: 0;
            min-width: 440px;
            .tag {
                top: 20px;
                right: 15px;
                left: auto;
            }
        }
        .content-post {
            padding-left: 40px;
            padding-right: 15px;
            .meta-post {
                li {
                    margin-right: 9px;
                    padding-right: 18px;
                    font-size: 15px;
                    line-height: 1.73;
                    span {
                        margin-right: 9px;
                        
                    }
                }   
            }
            .title {
                margin-bottom: 8px
            }
        }

        @include mobile {
            flex-wrap: wrap;
            .image {
                width: auto ;
                max-width: 100%;
                height: auto ;
            }

            .content-post {
                padding-left: 0;
                padding-top: 20px;
            }
        }
    }
    &.detail {
        .image {
            .tag {
                position: relative;
                left: 0;
                top: 0;
            }
        }
    }
}

.themesflat-pagination {
    ul {
        @include flex2;
        li {
            padding: 0;
            margin-right: 8px;
            a {
                @include flex(center,center);
                @include font-16;
                font-weight: $font-w7;
                font-family: $font-main;
                border: 1px solid $color-main2;
                background: transparent;
                width: 36px;
                height: 36px;
                color: $white;
                padding: 0;
                &.active,
                &:hover {
                    background: $bg-2;
                    border-color: $bg-2;
                }
            }
        }
    }
}

.grid {
    .article {
        width: 73.5%;
    }
    #sidebar {
        width: 26.5%;
        padding-left: 37px;
    }

    @include tablet {
        .article {
            width: 100%;
        }
        #sidebar {
            width: 100%;
            padding: 50px 15px 0;
        }
    }
}

.post-details {
    .detail .container{
        width: 960px;

        @include desktop-width {
            width: 100%;
        }
    }
    .page-title {
        padding: 108px 0 0 0;
        img {
            height: 800px;
        }
    }
    .detail {
        .article {
            .post,
            .post-details,
            .content-details {
                p,li {
                    font-size: 20px;
                    line-height: 1.6;
                    letter-spacing: 0;
                }
            }
        }
        }
    .detail {
        .post {
            margin-bottom: 33px;
            .image {
                margin-bottom: 21px;
                min-height: 32px;
                .tag.st2 {
                    position: absolute;
                    text-transform: capitalize;
                    font-weight: 400;
                    top: 0;
                    left: 0;
                    font-family: $font-main2;
                }  
            }
            .title {
                margin-bottom: 18px;
            } 
            .meta-post {
                li {
                    font-size: 17px;
                    line-height: 1.64;
                }
            }
        }
    }
    .post-details {
        margin-bottom: 40px;
        p {
            margin-bottom: 12px;
        }
        .image {
            margin-top: 40px;
        }
    }
    .content-details {
        margin-bottom: 32px;
        h6 {
            letter-spacing: 0;
            margin-bottom: 14px;
            text-transform: capitalize;
        }
        p {
            margin-bottom: 20px;
            &.st2 {
                margin-bottom: 12px;
            }
        }
        .list {
            margin-bottom: 20px;
            li {
                letter-spacing: 0;
                position: relative;
                padding-left: 20px;
                margin-bottom: 12px;
                svg {
                    position: absolute;
                    top: 13px;
                    left: 0;
                }
            }
        }
        &.mb-41 {
            margin-bottom: 41px;
        }
    }
}
.wrap-widget {
    margin-bottom: 59px;
    @include flex(center,space-between);
    .widget {
        &.widget-tag {
            @include flex(center,flex-start);
            
            ul li {
                margin-right: 9px;
                font-size: 17px;
                line-height: 1.64;
                span {
                    font-size: 16px;
                    line-height: 22px;
                    color: $color-st2;
                    font-weight: 400;  
                }
                a {
                    font-family: $font-main2;
                    font-size: 17px;
                    line-height: 1.64;
                }
            }
        }
        .title-widget {
            font-size: 16px;
            line-height: 1.5;
            color: $white;
            margin-right: 5px;
            text-transform: uppercase;
            letter-spacing: 0.6px;
            margin-bottom: 0;
        }
        &.widget-social {
            margin-top: -3px;
            .title-widget {
                margin-right: 11px
            }
            @include flex(center,center);
            h4 {
                margin-right: -8px
            }
            ul {
               @include flex2;
               li {
                   margin-left: 21px;
                   margin-right: 0;
                   font-size: 17px;
                   line-height: 1.64;
                    a {
                        -webkit-mask-image:none;
                        mask-image:none;
                        width: auto;
                        height: auto;
                        background: none;
                        color: $white;
                        &:hover {
                            color: $color-hover;
                        }
                        i {
                            font-size: 14px
                        }
                    } 
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.widget-tag ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrap-box-details {
    @include flex(flex-start,space-between);
    border-top: 1px solid rgba(255,255,255,0.1);
    border-bottom: 1px solid rgba(255,255,255,0.1);
    padding: 16px 0 12px;
    .previous,
    .next {
        width: 50%;
        a  {
            &.st {
                font-size: 14px;
                line-height: 1.57;
                font-weight:$font-w7;
                font-family: $font-main;
                margin-bottom: 3px;
                text-transform: uppercase;
                color: $color-main2;
            }
            &:hover {
                color: $bg-2;
            }
        }
        h6 {
            font-size: 20px;
            line-height: 1.3;
            color: $white;
        }
    }
    .previous {
        padding-right: 70px;
        position: relative;
        h6 {
            letter-spacing: 0;
        }
        &::after {
            position: absolute;
            right: 0;
            top: 13px;
            width: 1px;
            height: 60px;
            background: rgba(255,255,255,0.1);
            content: "";
        }
    }
    .next {
        text-align: right;
        padding-left: 95px;
        h6 {
            letter-spacing: 0;
        }
    }
}


.sl-post2 {
    .post-item {
        .content-item {
            .content {
                .h7 {
                    margin-bottom: 17px;
                    &.st {
                        padding-right: 60px;
                    }
                }
            }
        }
    }
}


/* animation slider */
.box-slider .content-box .wrap-btn,
.box-slider .content-box h1,
.box-slider .content-box h2,
.box-slider .content-box .sub-title {
    transform: translateY(200px);
    opacity: 0;
}

.box-slider .image {
    transform: translateX(200px);
    opacity: 0;
}

.swiper-slide-active .box-slider .image,
.swiper-slide-active .box-slider .content-box .wrap-btn,
.swiper-slide-active .box-slider .content-box h1,
.swiper-slide-active .box-slider .content-box h2,
.swiper-slide-active .box-slider .content-box .sub-title {
    opacity           : 1;
    visibility        : visible;
    -webkit-transition: transform 800ms ease, opacity 800ms ease;
    -moz-transition   : transform 800ms ease, opacity 800ms ease;
    -ms-transition    : transform 800ms ease, opacity 800ms ease;
    -o-transition     : transform 800ms ease, opacity 800ms ease;
    transition        : transform 800ms ease, opacity 800ms ease;
    transition-delay  : 1000ms;
}
.swiper-slide-active .box-slider .image {
    transform: translateX(0px);
}
.swiper-slide-active .box-slider .content-box .wrap-btn,
.swiper-slide-active .box-slider .content-box h1,
.swiper-slide-active .box-slider .content-box h2,
.swiper-slide-active .box-slider .content-box .sub-title {
    transform: translateY(0px);
}
.swiper-slide-active .box-slider .image,
.swiper-slide-active .box-slider .content-box h2,
.swiper-slide-active .box-slider .content-box h1 {
    transition-delay: 400ms;
}

.swiper-slide-active .box-slider .content-box .sub-title {
    transition-delay: 500ms;
}

.swiper-slide-active .box-slider .content-box .wrap-btn {
    transition-delay: 600ms;
}





.sl-team.swiper-container {
    @include showshadow;
    padding-left: 70px;
}

.wrap-team {
    margin-right: -307px;
    padding-left: 70px;

    @include tablet {
        margin-right: 0;
        padding-left: 30px;
    }
    @include mobile {
        margin-top: 50px;
        padding-left: 0;
    }
}


.item-parner {
    padding: 0 70px;
    margin-top: 67px;
    position: relative;
    .sl-parner {
        margin-bottom: 74px;
    }
    .image {
        img {
            @include transition5;
            transform: scale(1);
        }
    }
}


.featured-box {
    @include flex2;
    flex-wrap: wrap;
    margin-left: -$mb-30;
    .img-box {
        width: calc(25% - $mb-30);
        margin-left: $mb-30;
        margin-bottom: 32px;
    }
}

.image-faq {
    position: relative;
    img {
        mix-blend-mode: luminosity;
    }
}

.wrap-fx {
    .image-faq {
        width: 45%;
    }
    .content-faq {
        width: 55%;
        padding-left: 36px;
    }

    @include tablet {
        .image-faq{
            width: 100%;
            img {
                width: 100%;
            }
        }
        .content-faq{
            width: 100%;
            padding-left: 0;
            padding-top: 50px;
            .flat-accordion {
                max-width: 100%;
            }
        }
    }
}


.gallery {
    width: 100%;
    padding-left: 70px;
    padding-top: 2px;
    .button-fx {
        margin-top: 28px;
        margin-bottom: 24px;
        .btn-prev-team, 
        .btn-next-team {
            min-width: 32px;
            min-height: 32px;
            height: 32px;
            &.tf-button {
                .boder-fade {
                    border: 1px solid $bg-2;
                }
                .effect {
                    &::before,
                    &::after {
                        width: 1px;
                        background: $bg-2;
                    }
                }
                &::before,
                &::after {
                        height: 1px;
                        background: $bg-2;
                }
            }
            i {
                font-size: 20px;
            }
        }
        .btn-prev-team {
            margin-right: 16px;
        }
    }
    &-slider {
        width: 100%;
        height: auto;
        margin: 0 0 10px 0;

        .swiper-slide {
            .team-box {
                @include flex(flex-start,flex-start);
                background: none;
                border: none;
                &::after,
                &::before {
                    display: none;
                }
                .content {
                    padding: 38px 0 0 30px;
                    h5 {
                        margin-bottom: 6px;
                    }
                    p {
                        margin-bottom: 16px;
                    }
                    .desc {
                        color: $white;
                        font-size: 17px;
                        line-height: 1.64;
                        text-transform: none;
                        margin-bottom: 27px;
                    }
    
                }

                @include mobile {
                    flex-wrap: wrap;

                    .content {
                        padding: 38px 0 0 0;
                    }
                }
            }
        }
    }
    
    &-thumbs {
        .swiper-slide {
            width: 150px;
            height: 150px;
            text-align: left;
            overflow: hidden;
            .image {
                &::before {
                    @include transition5;
                }
                img {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    max-width: 150px;
                    max-height: 150px;
                    background: rgba(34, 183, 143, 0.1);
                }
            }
            
            &.swiper-slide-active {
                .image {
                    position: relative;
                    &::before {
                        border: 2px solid $bg-2;
                        position: absolute;
                        content: "";
                        top: 0;
                        left: 0;
                        width: $w100;
                        height: $w100;
                        z-index: 999;
                    }
                    
                }
            }

        }
    }
    .gallery-thumbs {
        margin-right: -260px;
        margin-top: 22px;
        .image {
            &::before {
                @include transition3;
            }
            &:hover {
                cursor: pointer;
                &::before {
                    border: 2px solid $bg-2;
                    position: absolute;
                    content: "";
                    top: 0;
                    left: 0;
                    width: $w100;
                    height: $w100;
                    max-width: 150px;
                    max-height: 150px;
                    z-index: 999;
                }
            }
        }

        @include mobile {
            margin-right: 0;
        }

    }

    @include desktop-width {
        padding-left: 0;
        padding-top: 60px;
    }
}
.gallery-slider {
    .swiper-slide {
        .team-box {
            box-shadow: none;
            img {
                border: 2px solid $bg-2;
                max-width: 330px;
                max-height: 330px;
                width: 330px;
                height: 330px;
            }
        }
    }
    .swiper-slide-active {
        .team-box {
            box-shadow: none;
            img {
                border: 2px solid $bg-2;
            }
        }
    }
}
.seclect-box {
    @include flex(flex-start,flex-end);
    .dropdown {
        margin-left: 20px;
        button {
            @include flex(center,flex-start);
            max-width: 240px;
            min-width: 240px;
            padding: 12px 15px 12px 20px;
            height: 52px;
            color: $color-main1;
            text-decoration: none;
            border: 1px solid rgba(255,255,255,0.2);
            background: transparent !important;
            border-radius: 0;
            &:after {
                @include topcenter;
                font-family: 'Font Awesome 5 Pro';
                font-size: 14px;
                font-weight: 400;
                content: "\f078";
                position: absolute;
                color: #B9B9BF;
                right: 20px;
                border: none;
            }
            &:focus {
                box-shadow: none !important;
                border-color: $color-hover !important;
            }
        }
        li span {
            @include transition3;
            font-size: 15px;
            line-height: 24px;
            color             : $white;
        }
        li {
            @include transition5;
            background: transparent;
            width: 100%;
            padding: 0 16px 0 20px;
            line-height: 45px;
            height: 45px;
            color: $white;
        }
        li.active,
        li:hover {
            background: $bg-2;
        }
    }
}

.seclect-box .dropdown-menu {
        max-width: 240px;
        min-width: 240px;
        
        width: 100%;
        left: 0;
        height: auto;
        padding: 0;
        top: 0;
        background: $bgr-body;
        border-radius: 0;
        transform: translate(0px, 52px) !important;
        border: none;
        box-shadow: 0 0px 32px 0 rgb(34 183 143 / 50%);
        .dropdown-item {
            padding: 0;
        }
        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: $w100;
            height: $w100;
            border-bottom: 1px solid rgba(255,255,255,0.2);     
            border-left: 1px solid rgba(255,255,255,0.2);  
            border-right: 1px solid rgba(255,255,255,0.2); 
            z-index: -1;
        }
        &:hover {
            cursor: pointer;
        }
    // &.show {
    //     a {
    //         background: transparent;
    //         width: 100%;
    //         padding: 9px 10px 8px 16px;
    //         @include transition3;
    //         font-size: 15px;
    //         line-height: 24px;
    //         color: $white;
    //         &:active,
    //         &:hover {
    //             background: $bg-2;
    //         }
    //     }
    // }
}

.fl-item {
    display: none;
}

.item-details {
    padding-left: 70px;
    h5 {
        color: $white;
        margin-bottom: 5px;
    }
    .sub {
        margin-bottom: 16px;
        color: $white;
    }
    .action {
        @include flex(center,space-between);
        margin-bottom: 27px;
        .right-box,
        .left-box {
            @include flex2;
            a {
                @include flex(center,center);
                font-size: 14px;
                line-height: 22px;
                color: $white;
                svg {
                    path {
                        @include transition3;
                    }
                }
                &:hover {
                    color: $bg-2;
                    svg {
                       path {
                        fill: $bg-2;
                       }
                    }
                }
            }
        }
        .left-box {
            a {
                margin-right: 14px;
                width: 75px;
                height: 36px;
                border-radius: 40px;
                svg {
                    margin-right: 7px;
                }
            }
        }
        .right-box {
            a {
                margin-left: 13px;
                width: 36px;
                height: 36px;
                border-radius: 50%;
            }
        }
    }
    .desc {
        color: $color-main1;
        font-size: 20px;
        line-height: 1.6;
    }
    .tf-button {
        @include flex(center,center);
        max-width: 100%;
        margin-bottom: 40px;
        margin-top: 29px;
        height: 52px;
        svg {
            margin-right: 10px;
            margin-left: 3px;
            margin-top: -5px;
        }
        &:hover {
            svg {
                path {
                    fill: $white;
                }
            }
        }
    }   
    .list-product {
        @include flex2;
        margin-left: -16px;
        flex-wrap: wrap;
        .box {
            margin-bottom: 16px;
            width: calc(33.3333% - 16px);
            margin-left: 16px;
            &.corner-box {
                padding: 20px 22px 10px;
                background: #0e2230;
                &::before,
                &::after {
                    background: #0e2230;
                }
                &::before {
                    width: calc(110% - 3rem);
                }
                &::after {
                    height: calc(120% - 3rem);
                }
            }
            p {
                font-size: 15px;
                line-height: 26px;
                color: $color-main1;
                margin-bottom: 4px;
            }
            .h7 {
                color: $white;
            }
            &:hover {
                box-shadow: $box-shadow;
            }
        }
    }     
}

.form-contact {
    fieldset {
        margin-bottom: 20px;
        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0; 
        }
    }
    input[type=text], 
    input[type=password], 
    input[type=datetime], 
    input[type=datetime-local], 
    input[type=date], 
    input[type=month], 
    input[type=time], 
    input[type=week], 
    input[type=number], 
    input[type=email], 
    input[type=url], 
    input[type=search], 
    input[type=tel], 
    input[type=color] {
        @include transition3;
        height: 52px;
        line-height: 1.73;
        padding: 13px 15px 13px 18px;
        font-size: 15px;
        color: $color-main1;
        &:focus {
            border-color: $color-hover;
        }
    }
    textarea {
        height: 110px;
        font-size: 15px;
        line-height: 1.73;
        padding: 20px 15px 13px 18px;
    }
    button {
        height: 66px;
        margin-top: 11px;
        padding: 0 40px;
    }
    input[type=text]::placeholder, 
    input[type=password]::placeholder, 
    input[type=datetime]::placeholder, 
    input[type=datetime-local]::placeholder, 
    input[type=date]::placeholder, 
    input[type=month]::placeholder, 
    input[type=time]::placeholder, 
    input[type=week]::placeholder, 
    input[type=number]::placeholder, 
    input[type=email]::placeholder, 
    input[type=url]::placeholder, 
    input[type=search]::placeholder, 
    input[type=tel]::placeholder, 
    input[type=color]::placeholder,
    textarea::placeholder {
        color: $color-main1;
    }
}

.post-details {
    .post-item {
        .content-item {
            bottom: -2px;
        }
    }
}

.flat-alert.msg-success {
    color: $bg-2;
}

.box-wallet-inner {
    @include flex2;
    margin-left: -30px;
    flex-wrap: wrap;
    .sc-box {
        width: calc(33.333% - 30px);
        margin-left: 30px;
        padding: 30px 20px;
        -webkit-border-radius: 16px;
        -ms-border-radius: 16px;
        -o-border-radius: 16px;
        -moz-border-radius: 16px;
        border-radius: 16px;
        -moz-box-shadow: 0px 3px 16px 0px #2F536D1F;
        -webkit-box-shadow: 0px 3px 16px 0px #2f536d1f;
        box-shadow: 0px 3px 16px 0px #2f536d1f;
        margin-bottom: 30px;
        background: var(--primary-color);
    }
}
.roadmap , .tf-team , .blog {
    .swiper-button-prev , .swiper-button-next {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 48px;
        min-height: 48px;
        background: transparent;
        border: 1px solid #14C2A3;
        margin-right: 15px;
        padding: 0;
        
        position: absolute;
    
        &.active,
        &:hover {
            background: $bg-2;
        }
        &::after {
            font-size: 30px;
            font-weight: 100;
            color: #fff;
        }
    }
    
}

.tf-team {
    .swiper-button-prev , .swiper-button-next {
        min-width: 32px;
        min-height: 32px;
        height: 32px;
        &::after {
            font-size: 20px;
        }
    }
}
.roadmap {
    .swiper-button-prev , .swiper-button-next {
        top: 107.5%;
    }
    
    .swiper-button-prev {
        left: 46.4%;
    }
    .swiper-button-next {
        right: 45.5%;
    }

    @include tablet  {
        .swiper-button-prev , .swiper-button-next{
            display: none;
        }
    }
}

.tf-team {
    .swiper-button-prev , .swiper-button-next {
        top: 80%;
    }
}

.tf-team.gallery {
    .team-box {
        padding-bottom: 80px;
    }
    .swiper-button-prev , .swiper-button-next {
        top: 97%;
    }
    
    .swiper-button-prev {
        left: 0;
    }
    .swiper-button-next {
        right: 87%;

        @include mobile {
            right: 75%;
        }
    }
}

.video {
    position: relative;
    .wrap-video {
        a {
            @include flex(center,center);
            position: absolute;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            padding-left: 5px;
            top: 41.5%;
            @include leftcenter;
            background: rgba(34, 183, 143, 0.1);
        }
    }       
}
.page-title {
    &.p404 {
        padding: 232px 0 239px;
        h1 {
            font-size: 300px;
            line-height: 1;
            color: $bg-2;
            margin-bottom: 1px;
        }
        h2 {
            text-transform: uppercase;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 39px;
        }
        .tf-button {
            height: 66px;
            line-height: 62px;
            padding: 0 40px;
        }
    }
}

.comimg-soon {
    text-align: center;
    background-size: cover;
    padding: 212px 0 167px !important;
    .header__logo {
        margin-bottom: 26px;
    }
    .sub-heading {
        margin-bottom: 40px;
        letter-spacing: 10.5px;
        text-transform: uppercase;
        font-weight: $font-w7;
        color: $color-main1;
        margin-left: 45px;
        font-size: 20px;
        line-height: 1.5;
        font-family: $font-main;
        span {
            margin: 0 28px 0 0;
        }
    }
    h3 {
        color: $white;
        letter-spacing: 55.6px;
        text-transform: uppercase;
        margin-bottom: 49px;
        margin-left: 56px;
        span {
            margin: 0 38px;
        }
    }
    .countdown__timer {
        @include flex(center,center);
        margin: 0 auto;
    }
    .countdown__item {
        margin: 0 20px;
        width: 80px;
        height: 80px;
        position: relative;
        text-align: center;
        padding: 0;
        line-height: 94px;
        background: #15293a;
        &::after,
        &::before {
            background: #15293a;
        }
        &::before {
            width: calc(130% - 3rem);
        }
        &::after {
            height: calc(130% - 3rem);
        }
        &:hover {
            box-shadow: $box-shadow;
        }
        span {
            font-size: 44px;
            line-height: 1.27;
            font-family: $font-main;
            color: $white;
            font-weight: 700;
        }
    }
    .featured-countdown {
        margin-bottom: 40px;
        ul {
            @include flex(center,center);
            padding-left: 18px;
            margin-top: 4px;
            li {
                margin-right: 60px;
                font-size: 20px;
                line-height: 1.5;
                color: $white;
                font-family: 'Urbanist', sans-serif;
                &:nth-child(2) {
                    padding-left: 13px;
                }
                &:nth-child(4) {
                    margin-right: 0;
                    margin-left: -12px;
                }
            }
        }
    }

    .widget-social li {
        margin: 0 19px 0 20px;
        a {
            background: none;
            width: auto;
            height: auto;
            --path:0;
            &:hover {
                background: none;
            }
    }
    }
    form {
        max-width: 600px;
        margin-bottom: 23px;
        margin-left: auto;
        margin-right: auto;
        
        &#subscribe-form {
            input {
                border-color: $bg-2;
                width: 100%;
                color: $color-main1;
                font-size: 15px;
                line-height: 1.73;
                &::placeholder {
                    font-size: 15px;
                    line-height: 1.73;
                    color: $color-main1;
                }
            }
            button {
                max-width: 148px;
                position: absolute;
                height: 48px;
                right: 8px;
                top: 8px;
                text-transform: uppercase;
                width: 148px;
                letter-spacing: 1px;
            }
        }
    }
}

.tf-contact {
    .contact-details {
        overflow: hidden;
        padding-left: 150px;
        .adress {
            margin-bottom: 43px;
            &.wrap-fx {
                .location,
                .mail {
                    width: 50%;
                    ul {
                        li {
                            font-size: 20px;
                            line-height: 1.6;
                        }
                    }
                }
                .location {
                    padding-right: 32px;
                }
                .mail {
                    padding-left: 30px;
                }
            }
            h6 {
                margin-bottom: 3px;
            }
        }
        .flat-map {
            height: 430px;
        }

        @include tablet {
            padding-top: 50px;
            padding-left: 0;
        }
    }
    
    .container {
        overflow: hidden;
    }
}

.team-detail {
    .info-detail {
        padding-left: 0;
        .sub {
            font-size: 20px;
            line-height: 1.6;
            color: $color-hover;
            font-weight: 700;
            letter-spacing: 1px;
            margin-bottom: 9px;
        }
        .name {
            text-transform: uppercase;
            margin-bottom: 28px;
        }
        .box {
            margin-bottom: 30px;
            padding-right: 110px;
            .h7 {
                text-transform: capitalize;
                margin-bottom: 12px;
            }
            p {
                margin-bottom: 12px;
            }
            &.contact {
                margin-bottom: 28px;
                p {
                    padding-right: 15px;
                    padding-right: 90px;
                    margin-bottom: 0;
                }
            }
        }
        .social {
            @include flex2;
            li {
                margin-right: 17px;
                a {
                    @include flex(center,center);
                    width: 40px;
                    height: 40px;
                    background: rgba(20, 194, 163, 0.4);
                    border-radius: 8px;
                    &:hover {
                        background: #14c2a3;
                    }
                }
            }
        }
    }
}

.tf-video {
    padding-bottom: 0 !important;
}
.page-item-details .tf-item-details  {
    padding-bottom: 0 ;
}

.page-item-details .tf-project {
    padding: 66px 0 80px;
}

.play-button {
    background-color: #000;
    border: 2px solid #008080;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 20px;
    height: 50px;
    justify-content: center;
    width: 50px;

    /* add the pulse animation */
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

/* style the play symbol when the audio is playing */
.play-button.playing i {
    color: #008080;
}

.audio-player button {
  background-color: black;
  border: none;
  outline: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.audio-player button:focus {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 teal;
  }
  70% {
    box-shadow: 0 0 0 10px transparent;
  }
  100% {
    box-shadow: 0 0 0 0 transparent;
  }
}


/* style the pause symbol when the audio is paused */
.play-button.paused i {
    color: #fff;
}
.fade-container {
  /* start with the background image hidden */
  opacity: 0;

  /* add the transition effect */
  transition: opacity 2s ease-in-out;
}

.fade-container.visible {
  /* make the background image visible */
  opacity: 1;
}
